<template>
  <v-row>
    <v-col>
      <v-breadcrumbs :items="items" class="tw-px-0">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            exact
            :to="item.href"
            :disabled="item.disabled"
            :class="$vuetify.breakpoint.smAndDown ? 'tw-text-xs tw-capitalize' : 'tw-capitalize'"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    items() {
      const items = [];
      const path = this.$route.path.split("/");
      const pageName = path[path.length - 1];
      path.forEach((element) => {
        if (element !== "") {
          items.push({
            text: element.replaceAll('-', ' '),
            disabled: element === pageName || element === "edit"  ||  element === "detail",
            href:
              element !== "dashboard" ? "/dashboard/" + element : "/" + element,
          });
        }
      });
      return items;
    },
  },
};
</script>

<style></style>
