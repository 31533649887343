import api from "./api";

const endPoint = "/visitors";

export const detail = (id) =>
  api
    .get(`${endPoint}/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const changePassword = async (id) =>
  await api.post("/users/change-password-visitor", {
    id: id,
  });

export const remove = (id) =>
  api
    .get(`${endPoint}/remove/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });
