<template>
  <v-card-text>
    <v-row>
      <v-col cols="12" md="6">
        <skeleton-card v-if="Object.keys(profile).length === 0" />
        <v-card
          v-else
          outlined
          shaped
          min-height="200"
          class="tw-shadow-xl tw-p-4 tw-flex tw-items-center"
        >
          <v-row justify="center" align="center">
            <modal-view-logo :logo="logo" />
            <v-col cols="6">
              <div class="tw-flex tw-items-center">
                <v-btn
                  icon
                  depressed
                  dark
                  :small="$vuetify.breakpoint.mdAndUp"
                  :x-small="$vuetify.breakpoint.mdAndDown"
                  color="primary"
                >
                  <v-icon>mdi-cog</v-icon>
                </v-btn>

                <span
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'ml-2 tw-text-gray-400 tw-tracking-normal tw-text-base tw-font-medium'
                      : 'ml-2 tw-text-gray-400 tw-tracking-normal tw-text-sm tw-font-medium'
                  "
                >
                  {{ profile.role.name }}
                </span>
              </div>
              <h2
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'tw-text-gray-800 tw-font-bold tw-text-xl'
                    : 'tw-text-gray-800 tw-font-bold tw-text-lg'
                "
              >
                {{ profile.name }}
              </h2>
              <v-tooltip top nudge-right="-60" nudge-top="20">
                <template v-slot:activator="{ on, attrs }">
                  <h6
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      $vuetify.breakpoint.mdAndUp
                        ? 'tw-text-gray-800 tw-text-lg tw-font-normal'
                        : 'tw-text-gray-800 tw-text-base tw-font-normal'
                    "
                  >
                    {{ profile.username }}
                  </h6>
                </template>
                <span>Username</span>
              </v-tooltip>

              <h6
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'tw-text-gray-400 tw-text-lg tw-font-normal'
                    : 'tw-text-gray-400 tw-text-base tw-font-normal'
                "
              >
                {{ profile.email }}
              </h6>
              <v-btn @click.prevent="submit" small color="primary" class="tw-tracking-wide tw-capitalize tw-mt-2">
                change password
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- <visitor-change-password :profile="profile" :current-user="currentUser" /> -->
    </v-row>
  </v-card-text>
</template>

<script>
import { changePassword } from "@/api/visitor";
import Swal from "sweetalert2";
import form from "@/mixins/form";
import ModalViewLogo from "./ModalViewLogo.vue";
import SkeletonCard from "./SkeletonCard.vue";
// import VisitorChangePassword from "./VisitorChangePassword.vue";
export default {
  components: { ModalViewLogo, SkeletonCard },
  mixins: [form],
  props: {
    profile: {
      type: Object,
      required: true,
    },
    currentUser: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      logo:
        "https://ik.imagekit.io/5zmdzllq3/avatar_logo_rXKJaXXumdI.svg",
    };
  },
  methods: {
    async submit() {
      try {
        this.$store.dispatch("auth/setLoading");
        const { data } = await changePassword(this.profile.id);
        await this.currentUser();
        this.$store.dispatch("auth/setLoading");
        this.errors = {};
        Swal.fire({
          icon: "success",
          title: data.message,
          timer: 1500,
        });
      } catch (error) {
        this.$store.dispatch("auth/setLoading");
        if (error.response) {
          this.errors = error.response.data.errors;
        } else {
          console.log(error);
        }
      }
    },
  }
};
</script>

<style></style>
