var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(Object.keys(_vm.profile).length === 0)?_c('skeleton-card'):_c('v-card',{staticClass:"tw-shadow-xl tw-p-4 tw-flex tw-items-center",attrs:{"outlined":"","shaped":"","min-height":"200"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('modal-view-logo',{attrs:{"logo":_vm.logo}}),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('v-btn',{attrs:{"icon":"","depressed":"","dark":"","small":_vm.$vuetify.breakpoint.mdAndUp,"x-small":_vm.$vuetify.breakpoint.mdAndDown,"color":"primary"}},[_c('v-icon',[_vm._v("mdi-cog")])],1),_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                    ? 'ml-2 tw-text-gray-400 tw-tracking-normal tw-text-base tw-font-medium'
                    : 'ml-2 tw-text-gray-400 tw-tracking-normal tw-text-sm tw-font-medium'},[_vm._v(" "+_vm._s(_vm.profile.role.name)+" ")])],1),_c('h2',{class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'tw-text-gray-800 tw-font-bold tw-text-xl'
                  : 'tw-text-gray-800 tw-font-bold tw-text-lg'},[_vm._v(" "+_vm._s(_vm.profile.name)+" ")]),_c('v-tooltip',{attrs:{"top":"","nudge-right":"-60","nudge-top":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('h6',_vm._g(_vm._b({class:_vm.$vuetify.breakpoint.mdAndUp
                      ? 'tw-text-gray-800 tw-text-lg tw-font-normal'
                      : 'tw-text-gray-800 tw-text-base tw-font-normal'},'h6',attrs,false),on),[_vm._v(" "+_vm._s(_vm.profile.username)+" ")])]}}])},[_c('span',[_vm._v("Username")])]),_c('h6',{class:_vm.$vuetify.breakpoint.mdAndUp
                  ? 'tw-text-gray-400 tw-text-lg tw-font-normal'
                  : 'tw-text-gray-400 tw-text-base tw-font-normal'},[_vm._v(" "+_vm._s(_vm.profile.email)+" ")]),_c('v-btn',{staticClass:"tw-tracking-wide tw-capitalize tw-mt-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v(" change password ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }