<template>
  <v-container fluid>
    <v-card tile flat>
      <v-card-text>
        <breadcrumb-component/>
      </v-card-text>
      <visitor-profile :current-user="getProfile" :profile="profile" />
    </v-card>
  </v-container>
</template>

<script>
import { detail } from "@/api/visitor";
import VisitorProfile from '../components/VisitorProfile.vue'
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue';
export default {
  components: { VisitorProfile, BreadcrumbComponent },
  data() {
    return {
      profile: {},
    };
  },
  async created() {
    await this.getProfile();
  },
  methods: {
    async getProfile() {
      const {
        data: { data },
      } = await detail(this.$route.params.id);
      this.profile = data;
    },
  },
}
</script>

<style>

</style>